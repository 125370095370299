
import { Options, Vue } from 'vue-class-component'
import NaviBar from '@/components/NaviBar.vue'
import service from '@/utils/request'
import { Dimension, Question, Survey } from '@/models/plan'
import { Dialog, Toast } from 'vant'

@Options({
  components: {
    NaviBar
  }
})
export default class PracticeTest extends Vue {
  surveyId = ''
  taskId = ''
  isPopShow = true
  survey: Survey = {
    cover: '',
    createTime: '',
    createdBy: 0,
    dimensions: [],
    id: 0,
    introduction: '',
    name: '',
    questionNum: 0,
    questions: [],
    title: ''
  }

  currentIndex = 0
  answers = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N']

  get currentQuestion (): Question {
    return this.survey.questions[this.currentIndex]
  }

  mounted () {
    this.surveyId = this.$route.params.surveyId as string
    this.taskId = this.$route.params.taskId as string
    this.loadData()
  }

  tapNext () {
    if (this.currentQuestion.answer === 0) {

    } else if (!this.currentQuestion.answer) {
      Toast({
        message: '请选择答案',
        duration: 2000
      })
      console.log('this.currentQuestion.answer==', this.currentQuestion.answer)
      return
    }
    if (this.currentIndex === this.survey.questions.length - 1) {
      this.submitRequest()
    } else {
      this.currentIndex += 1
    }
  }

  goBack () {
    Dialog.confirm({
      title: '是否退出问卷',
      confirmButtonText: '确定'
    })
      .then(() => {
        // on confirm
        this.$router.go(-1)
      })
      .catch(() => {
        // on cancel
      })
  }

  submitRequest () {
    const arr: {
      answer: string
      questionId: number
    }[] = []
    this.survey.questions.forEach(item => {
      const q = {
        answer: '',
        questionId: 0
      }
      q.answer = this.answers[item.answer]
      q.questionId = item.id
      arr.push(q)
    })

    service.post('/mindup/mini/tasks/' + this.taskId + '/survey-task/complete', {
      surveyAnswerList: arr
    }).then(res => {
      console.log(res)
      Toast.success({
        message: '问卷完成,自动跳转.',
        duration: 2000
      })
      setTimeout(() => {
        this.$router.go(-1)
      }, 2000)
    })
  }

  loadData () {
    /// mini/plan-surveys/{id}
    service.get('/mindup/mini/plan-surveys/' + this.surveyId, {}).then(res => {
      this.survey = res.data.survey
    })
  }

  close () {
    this.isPopShow = false
  }
}
